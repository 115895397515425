import { Injectable } from '@angular/core';
import amplitude from 'amplitude-js';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {

  private initialized = false;
  private trackingOptions = {
    city: false,
    country: false,
    carrier: false,
    ip_address: false,
    region: false,
    dma: false,
    latitude: false,
    longitude: false
  };

  constructor() {}

  logEvent(type, properties?: any): void {
    if (!this.initialized) {
      amplitude.getInstance().init(environment.amplitudeKey, null, {trackingOptions: this.trackingOptions});
      this.initialized = true;
    }
    if (properties) {
      properties['from'] = 'Web';
      amplitude.getInstance().logEvent(type, properties);
    } else {
      amplitude.getInstance().logEvent(type, {from: 'Web'});
    }
  }

}
