import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Helpers } from '../shared/components/helpers';
import { HttpClient } from '@angular/common/http';
import { MeModel } from '../models/me.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private meFetched: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public meFetched$ = this.meFetched.asObservable();
  private msalUserFetched: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public msalFetched$ = this.msalUserFetched.asObservable();
  authUser: MeModel;

  readonly EMAIL_PATTERN: RegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private http: HttpClient,
    private translate: TranslateService
  ) {}

  setLanguage(lang) {
    console.log(lang);
    this.translate.use(lang);
    Helpers.changeLocale(lang);
  }

  getLanguage() {
    return this.translate.currentLang;
  }

  getAuthUser(): MeModel {
    if (this.authUser) {
      return this.authUser;
    }
    this.authUser = JSON.parse(localStorage.getItem('user'));
    return this.authUser;
  }
  setMsalUserFetched(fetched: boolean) {
    this.msalUserFetched.next(fetched);
  }

  getEmployees(userId: number) {
    return this.http.get(environment.apiUrl + 'users/' + userId + '?with=employees')
      .toPromise()
      .then((response: any) => {
        const data = response.data;
        return data.employees;
      });
  }
  enable2FA(userId): Observable<any> {
    return this.http.post(environment.apiUrl + 'auth/2fa/enable', {});
  }

  confirm2FA(code): Observable<any> {
    return this.http.post(environment.apiUrl + 'auth/2fa/confirm', {code});
  }

  disable2FA(): Observable<any> {
    return this.http.delete(environment.apiUrl + 'auth/2fa/disable');
  }

  regenerate2FACodes(): Observable<any> {
    return this.http.post(environment.apiUrl + 'auth/2fa/regenerate-codes', {});
  }



  getMe(): Promise<any> {
    return this.http
      .get(environment.apiUrl + 'auth/me')
      .toPromise()
      .then((response: any) => {
        const d = response;
        if (d.permissionItems) {
          let generic = [];
          Object.keys(d.permissionItems).map(p => {
            generic = [...generic, ...d.permissionItems[p]];
          });
          generic = generic.filter((item, pos, ar) => ar.indexOf(item) === pos);
          d.permissionItems['generic'] = generic;
        }
        this.meFetched.next(true);
        return d;
      })
      .catch(this.handleError);
  }

  lostPassword(params): Promise<any> {
    return this.http
      .post(environment.apiUrl + 'auth/lostpw', params)
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  setPassword(params): Promise<any> {
    return this.http
      .post(environment.apiUrl + 'auth/setpw', params)
      .toPromise()
      .then(response => response);
  }
  logout(): Promise<any> {
    return this.http
      .post(environment.apiUrl + 'auth/logout', {})
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  getUsers(filters): Promise<any[]> {
    return this.http
      .get(environment.apiUrl + 'users', filters)
      .toPromise()
      .then((response: any) => response.data)
      .catch(this.handleError);
  }

  getOrganisationUsers(orgId) {
    return this.http
      .get(environment.apiUrl + 'organisations/' + orgId + '/users')
      .toPromise()
      .then((response: any) => response.data)
      .catch(this.handleError);
  }

  getUser(id): Promise<any> {
    return this.http
      .get(environment.apiUrl + 'users/' + id)
      .toPromise()
      .then((response: any) => response.data)
      .catch(this.handleError);
  }

  addUser(user): Promise<any> {
    return this.http
      .post(environment.apiUrl + 'users', user)
      .toPromise()
      .then((response: any) => response.data)
      .catch(this.handleError);
  }

  updateUser(id, user): Promise<any> {
    return this.http
      .put(environment.apiUrl + 'users/' + id, user)
      .toPromise()
      .then((response: any) => response.data)
      .catch(this.handleError);
  }
  adminUpdateUser(id, params): Promise<any> {
    return this.http
      .put(environment.apiUrl + 'admin/users/' + id, params)
      .toPromise()
      .then((response: any) => response.data)
      .catch(this.handleError);
  }
  adminUpdateUserPassword(id, params): Promise<any> {
    return this.http
      .put(environment.apiUrl + 'admin/users/' + id + '/setPassword', params)
      .toPromise()
      .then((response: any) => response.data)
      .catch(this.handleError);
  }
  adminResetUsersPassword(id): Promise<any> {
    return this.http
      .post(environment.apiUrl + 'admin/users/' + id + '/passwordreset', id)
      .toPromise()
      .then((response: any) => response.data)
      .catch(this.handleError);
  }
  deleteUser(id): Promise<any> {
    return this.http
      .delete(environment.apiUrl + 'users/' + id)
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  register(data): Promise<any> {
    return this.http
      .post(environment.apiUrl + 'auth/register/', data)
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  updateEmployees(id, params): Promise<any> {
    return this.http
      .put(environment.apiUrl + 'users/' + id + '/employees', params)
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  validateEmailChange(code) {
    return this.http.put(environment.apiUrl + 'emailconfirmation', {code})
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}
