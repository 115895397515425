import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { UserService } from './services/user.service';
import { Subject } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { MicrosoftLoginService } from './services/microsoft-login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  organisationCheckCompleted = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private microsoftLoginService: MicrosoftLoginService
  ) {}

  ngOnInit() {
    this.checkOrganisationChange();
    this.microsoftLoginService.listenToMicrosoftLoginEvents();
  }

  ngOnDestroy() {
    this._destroying$.next();
    this._destroying$.complete();
  }

  checkOrganisationChange(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationStart), takeUntil(this._destroying$)).subscribe(async () => {
      // If url has orga_id it means we want to change organisation. Let's block router-outlet until this is finished
      const orgaIdParam = this.route.snapshot.queryParams.orga_id;
      if (orgaIdParam) {
        await this.changeOrganisation(parseInt(orgaIdParam, 10));
        this.organisationCheckCompleted = true;
      } else {
        this.organisationCheckCompleted = true;
      }
    });
  }

  async changeOrganisation(organisationId: number) {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user?.id) {
      await this.userService.updateUser(user.id, {organisation_id: organisationId});
      const meResult = await this.userService.getMe();
      localStorage.setItem('user', JSON.stringify(meResult));
    }
  }

}
