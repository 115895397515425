import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './user.service';
import { MsalService } from '@azure/msal-angular';
import { AmplitudeService } from './amplitude.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private jwt: JwtHelperService,
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    private translate: TranslateService,
    private userService: UserService,
    private msalService: MsalService,
    private amplitude: AmplitudeService,
    private route: ActivatedRoute,
  ) { }

  isAuthenticated() {
    const token = localStorage.getItem('impact-token');
    return !this.jwt.isTokenExpired(token);
  }

  login(user) {
    return this.http.post(environment.apiUrl + 'auth/login', user);
  }

  async handleLoginResponse(loginResponse: any): Promise<string> {
    if (!loginResponse?.user?.organisation_id) {
      return Promise.reject('You are not part of any Impact organisation - cannot login');
    }

    localStorage.setItem('impact-token', loginResponse.token);

    try {
      const user = await this.userService.getMe();
      this.userService.authUser = user;
      localStorage.setItem('user', JSON.stringify(user));
      this.userService.setLanguage(user.language.code);

      this.amplitude.logEvent('Login', { from: 'web' });
      window.dispatchEvent(new CustomEvent('login'));

      const redirect = this.route.snapshot.queryParamMap.get('redirect') || '/';
      if (this.userService.userHasOnlyChemicals()) {
        this.router.navigate(['/chemicals']);
      } else {
        this.router.navigateByUrl(redirect);
      }

      return Promise.resolve('');
    } catch (error) {
      return Promise.reject('Failed to fetch user data during login');
    }
  }

  logout() {
    const msalAccount = this.msalService.instance.getActiveAccount();
    this.http.post(environment.apiUrl + 'auth/logout', {}).subscribe(() => {
      this.logoutTasks(!!msalAccount);
      if (msalAccount) {
        this.msalService.instance.setActiveAccount(null);
        setTimeout(() => {
          this.msalService.logoutRedirect({
            postLogoutRedirectUri: environment.oauthData.microsoftOauth.logoutRedirectUri
          });
        }, 250); // Add a slight delay for the msalService to have time to clear the account
      } else {
        this.router.navigateByUrl('/login');
      }
    }, () => {
      this.toastr.error(this.translate.instant('Cannot logout'));
    });
  }

  private logoutTasks(preserveFlag = false): void {
    localStorage.clear();
    sessionStorage.clear();
    this.userService.authUser = null;
    window.dispatchEvent(new CustomEvent('logout'));
    if (preserveFlag) {
      localStorage.setItem('impact-sso-logout-in-progress', 'true');
    }
  }
}
